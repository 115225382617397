import Layout from "../components/Layout";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Card, CardContent, Grid, Typography } from "@material-ui/core";
import qs from 'qs';

const useStyles = makeStyles((theme) => ({
  flexCenter:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  },
  cardContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '25px 0 25px 0',
  },
  cardLogin: {
    width: '100%',
    minHeight: '560px',
    borderRadius: '33px',
    boxShadow: '0px 5px 35px rgba(0, 0, 0, 0.25)',
    background: 'linear-gradient(135deg, rgba(255,255,255,0.7) 0%,  rgba(255,255,255,0.7) 18%,  rgba(208,241,255,0.7) 145%)',
    display:'flex',
    flexDirection: 'column',
    justifyContent:'center',
    animation: '$card-show 0.75s ease 1',
    [theme.breakpoints.down('lg')]: {
      minWidth: '512px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
      borderRadius: '25px',
    },
    [theme.breakpoints.down(350)]: {
      minWidth: '100%',
      borderRadius: '20px',
    },
  },
  logoImg:{
    width: 'auto',
    height: '70px',
    objectFit: 'cover',
    [theme.breakpoints.down(500)]: {
      height: '65px',
    },
  },
  permissionContainer: {
    position: 'relative',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
    marginBottom: '15px',
    [theme.breakpoints.down(500)]: {
      width: '90%', 
    },
  },
  lineButton: {
    background: '#00C300',
    color: 'white',
    borderRadius: 5,
    width: 300,
    height: 50,
    fontSize: 16,
    '&:hover': {
      background: '#10b310',
    },
  },
  loginText: {
    fontSize: 18,
    padding: '10px 0px',
    margin: 0,
  },
}))

export default ({}) => {
  const classes = useStyles();

  const login = () => {
    const response_type = "code";
    const client_id = process.env.GATSBY_LINE_CLIENT_ID;
    const redirect_uri = `${window.location.origin}/lineLoginCallback`;
    const state = Math.random().toString(36).substring(7);
    const scope = "openid profile";
    const params = {
      response_type,
      client_id,
      redirect_uri,
      state,
      scope
    }
    window.location = `https://access.line.me/oauth2/v2.1/authorize?${qs.stringify(params)}`;
  }

  return (
    <Layout hideAppBar>
      <div className={classes.flexCenter} style={{ height: '100vh' }}>
        <Grid container spacing={0}
          justifyContent='center'
          alignItems='center'
        >
          <Grid item xs={12} sm={5} md={5} xl={5} className={classes.flexCenter}>
            <Card variant="outlined" className={classes.cardLogin}>
              <CardContent style={{height:'100%'}}>
                <Box textAlign="center" className={classes.cardContent}>
                  <div className={classes.permissionContainer}>
                    <img className={classes.logoImg} src={require(`${'../assets/images/LogoMorwithLetter.png'}`)} alt='' />
                  </div>
                  <p className={classes.loginText}>กรุณาเข้าสู่ระบบ</p>
                  <Button onClick={login} className={classes.lineButton}>
                    เข้าสู่ระบบผ่านไลน์
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Layout>
  )
}


// import React, { useEffect } from 'react';
// import * as Api from '../apis';
// import btnLine from '../../content/assets/btn_login_base.png';
// import { makeStyles } from '@material-ui/core/styles';
// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';
// import { useSelector, useDispatch } from 'react-redux';
// import { navigate } from 'gatsby';
// import desktopBG from '../../content/assets/desktop_bg.jpg';
// import mobileBG from '../../content/assets/mobile_bg.jpg';
// import Logo from '../components/Logo';
// // import FacebookLogin from 'react-facebook-login';
// import { becomeFacebook, logInFacebook } from '../actions/session';
// import qs from 'qs';

// const useStyles = makeStyles(theme => ({
//   lineButton: {
//     background: '#00C300',
//     color: 'white',
//     borderRadius: 5,
//     width: 300,
//     height: 50,
//     fontSize: 16,
//     '&:hover': {
//       background: '#10b310',
//     },
//   },
//   container: {
//     [theme.breakpoints.down('sm')]: {
//       background: `url(${mobileBG})`,
//       backgroundSize: '100% 100%',
//       backgroundRepeat: 'no-repeat',
//     },
//     background: `url(${desktopBG})`,
//     backgroundSize: '100% 100%',
//     backgroundRepeat: 'no-repeat',
//     minHeight: '100vh',
//   },
//   header: {
//     fontSize: 18,
//     margin: 0,
//     paddingTop: 20,
//     fontWeight: 'bold',
//     color: 'teal',
//   },
//   information: {
//     padding: '0px 60px',
//     fontSize: 18,
//     color: 'black',
//     textAlign: 'center',
//   },
//   loginText: {
//     fontSize: 18,
//     padding: '10px 0px',
//     margin: 0,
//   },
//   facebookButton: {
//     borderRadius: 5,
//     width: 300,
//     height: 50,
//     fontSize: 16,
//     color: '#fff',
//     cursor: 'pointer',
//     backgroundColor: '#4c69ba',
//     border: 'calc(.06887vw + .67769px) solid #4c69ba',
//     textTransform: 'uppercase',
//     marginBottom: 10,
//     '&:hover': {
//       background: '#2a4ba9',
//     },
//   },
// }));

// const LoginPage = ({ location }) => {
//   const classes = useStyles();
//   const { isReady, user } = useSelector(state => state.session);
//   const dispatch = useDispatch();
//   const query = qs.parse(location.hash);
//   const accessToken = query['#access_token'];

//   useEffect(() => {
//     if (isReady && user) {
//       const returnUri = localStorage.getItem('returnUri');
//       navigate(returnUri || '/');
//     }
//   }, [isReady, user]);

//   useEffect(() => {
//     localStorage.removeItem('token');
//     // initFacebookApi();

//     if (accessToken) {
//       dispatch(becomeFacebook({ accessToken }));
//       dispatch(logInFacebook(accessToken));
//     }
//   }, []);

//   function login() {
//     const response_type = 'code';
//     const client_id = process.env.GATSBY_LINE_CLIENT_ID;
//     const redirect_uri = `${window.location.origin}/lineLoginCallback`;
//     const state = Math.random()
//       .toString(36)
//       .substring(7);
//     const scope = 'openid profile';
//     window.location = `https://access.line.me/oauth2/v2.1/authorize?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}&state=${state}&scope=${scope}`;
//   }

//   // function responseFacebook(res) {
//   //   dispatch(becomeFacebook(res));
//   //   dispatch(logInFacebook(res.accessToken));
//   // }

//   function initFacebookApi() {
//     window.fbAsyncInit = function() {
//       window.FB.init({
//         appId: process.env.GATSBY_FACEBOOK_CLIENT,
//         cookie: true,
//         xfbml: true,
//         version: 'v2.5',
//       });
//     };
//     (function(d, s, id) {
//       var js,
//         fjs = d.getElementsByTagName(s)[0];
//       if (d.getElementById(id)) {
//         return;
//       }
//       js = d.createElement(s);
//       js.id = id;
//       js.src = 'https://connect.facebook.net/en_US/sdk.js';
//       fjs.parentNode.insertBefore(js, fjs);
//     })(document, 'script', 'facebook-jssdk');
//   }

//   function facebookLogin() {
//     const redirect_uri = `${window.location.origin}/login`;
//     const APP_ID = process.env.GATSBY_FACEBOOK_CLIENT;
//     window.location = encodeURI(
//       `https://www.facebook.com/dialog/oauth?client_id=${APP_ID}&redirect_uri=${redirect_uri}&response_type=token`,
//     );
//     // window.FB.getLoginStatus(function(response) {
//     //   if (response.status === 'connected') {
//     //     const authResponse = response.authResponse;
//     //     dispatch(becomeFacebook(authResponse));
//     //     dispatch(logInFacebook(authResponse.accessToken));
//     //   } else {
//     //     const redirect_uri = `${window.location.origin}/login`;;
//     //     const APP_ID = process.env.GATSBY_FACEBOOK_CLIENT;
//     //     window.location = encodeURI(`https://www.facebook.com/dialog/oauth?client_id=${APP_ID}&redirect_uri=${redirect_uri}&response_type=token`);
//     //   }
//     // });
//   }

//   if (accessToken) {
//     return <> </>;
//   }

//   return (
//     <div className={classes.container}>
//       <Logo />
//       <Grid container spacing={0} direction='column' alignItems='center'>
//         <Grid item></Grid>
//         <Grid item>
//           <p className={classes.header}>เพื่อความเป็นส่วนตัว</p>
//         </Grid>
//         <Grid item>
//           <p className={classes.information}>
//             (ในกรณี Login ผ่าน Line)
//             <br /> ผลของการประเมินทั้งหมด จะถูกส่งไปยังบัญชี Line ส่วนตัว
//             ของคุณเท่านั้น
//           </p>
//         </Grid>
//         <Grid item>
//           <p className={classes.loginText}>กรุณาทําการเช่ือมต่อ</p>
//         </Grid>
//         <Grid item>
//           {/* <FacebookLogin
//             appId={process.env.GATSBY_FACEBOOK_CLIENT}
//             cssClass={classes.facebookButton}
//             redirectUri="/login"
//             callback={responseFacebook}
//             disableMobileRedirect={true}
//           /> */}
//           {/* <Button onClick={facebookLogin} className={classes.facebookButton}>Login with facebook account</Button> */}
//         </Grid>
//         <Grid item>
//           <Button onClick={login} className={classes.lineButton}>
//             Login with LINE account
//           </Button>
//         </Grid>
//       </Grid>
//     </div>
//   );
// };

// export default LoginPage;
